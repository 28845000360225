<template>
  <div class="course-wrapper">
    <div class="course-wrapper-title">
			<span>用户协议</span>
      <div class="right-button">
        <el-button @click="handleButton" type="text" icon="el-icon-edit">编辑</el-button>
      </div>
		</div>
    <div class="course-wrapper-body">
      <article class="content" v-html="content"></article>
    </div>

    <content-dialog :contentData="contentData" @closeContentData="closeContentData"></content-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import contentDialog from './contentDialog.vue';
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: 'userAgreement',
  components: {
    contentDialog,
  },
  data() {
    return {
      content: '',
      contentData: {},
      isEdit: false, //编辑权限
      permissionButtonList: [], //权限列表
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isEdit = val.indexOf("userAgreement:update") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    //menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    this.$api.getAboutUs({ flag: 0 })
      .then(res => {
        if (res.data.code) {
          this.$message.error(res.data.msg);
          return;
        }
        this.content = res.data.data;
      })
      .catch(e => {
        this.$message.error(e);
      });
  },
  methods: {
    getPermissionButton,
    handleButton() {
      this.contentData = {
        content: this.content
      };
    },
    closeContentData(data) {
      this.content = data.content;
      this.$api.postAboutUs({
        flag: 0,
        text: this.content
      })
        .then(res => {
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }
          this.visible = false;

          this.$message.success('修改成功');
        })
        .catch(e => {
          this.$message.error(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
.course-wrapper-body {
  min-height: 300px;
}
</style>
